@use "src/bundles/UIAppBundle/styles/colors.scss" as c;
@use "src/bundles/UIAppBundle/styles/variables.scss" as v;
@use "src/bundles/UIAppBundle/styles/mixins.scss" as m;

.page-wines-edit {
  &-select-container {
    @include m.flex(column, center, flex-start);
    gap: v.$margin-2;
    margin: v.$margin-9 0;
  }
  &-badge-list {
    margin-bottom: v.$margin-4;
  }
  &-calculate-container {
    @include m.flex(row, center, flex-start);

    .item {
      flex: 1;
    }
  }
}

.of-which-sugar {
  .ant-col.ant-form-item-label {
    padding-left: 15px;
  }
}
