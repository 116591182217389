@use "src/bundles/UIAppBundle/styles/colors.scss" as c;
@use "src/bundles/UIAppBundle/styles/variables.scss" as v;
@use "src/bundles/UIAppBundle/styles/mixins.scss" as m;

.container {
  width: 100%;
  box-sizing: border-box;

  @include m.flex(row, flex-start, center, wrap);
  gap: v.$margin-5;

  .item {
    border-radius: v.$border-radius-4;
    background-color: c.$gray-5;
    padding: v.$padding-1 v.$padding-3;
    @include m.clickable;
    @include m.flex(row, center, center, nowrap);
    gap: v.$margin-2;

    p{
      margin: 0;
    }
  }
}
