@use "src/bundles/UIAppBundle/styles/colors.scss" as c;
@use "src/bundles/UIAppBundle/styles/variables.scss" as v;
@use "src/bundles/UIAppBundle/styles/mixins.scss" as m;

.container {
  width: min-content;
  padding: v.$padding-3;
  box-sizing: border-box;
  background-color: c.$gray-2;
  border-radius: v.$border-radius-2;

  @include m.flex(row, center, center);
}
