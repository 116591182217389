@use "src/bundles/UIAppBundle/styles/colors.scss" as c;
@use "src/bundles/UIAppBundle/styles/variables.scss" as v;
@use "src/bundles/UIAppBundle/styles/mixins.scss" as m;

.page-wines-list {
  &-qr-modal {
    width: 100%;
    @include m.flex(row, center, center, wrap);
    gap: v.$margin-3;

    &-container {
      @include m.flex(column, center, center);
      gap: v.$margin-2;
    }
  }
}
