$primary: #0086c9;
$primary-darker: #0b4a6f;
$primary-dark: #065986;
$primary-light: #026aa2;
$primary-lighter: #7cd4fd;
$light-blue: #f2f4f7;
$lighter-blue: #f0f9ff;
$primary-25: #f5fbff;

$black: #000000;
$black-1: #101828;
$black-2: #0f1527;
$white: #ffffff;
$gray: #f2f4f7;
$gray-1: #eaecf0;
$gray-2: #d0d5dd;
$gray-3: #e0f2fe;
$gray-4: #fcfcfd;
$gray-5: #e8e8e8;
$gray-6: #b4bac5;
$gray-7: #98a2b3;
$placeholder-light: #667085;
$bg: #f9fafb;

$success: #12b76a;
$success-light: #d1fadf;
$success-dark: #009444;

$error: #d92d20;
$error-light: #fee4e2;

$warning-700: #b54708;
$warning-500: #f79009;
$warning-50: #fffaeb;

$success-300: #6ce9a6;

// naming according to weight
$grey-900: #101828;
$grey-700: #344054;
$grey-500: #667085;
$grey-400: #98a2b3;
$grey-300: #d0d5dd;
$grey-200: #eaecf0;
$grey-100: #f2f4f7;
$grey-50: #f9fafb;
$grey-25: #fcfcfd;

$primary-900: #0b4a6f;
$primary-800: #065986;
$primary-700: #026aa2;
$primary-600: #0086c9;
$primary-50: #f0f9ff;

$blue-1: #0ba5ec;
$green-light: #ecfdf3;
$purple: #5925dc;
$purple-light: #f4f3ff;
$orange-light-1: #fdede9;
$orange-light-2: #f4a68e;
$orange-dark-1: #ea7551;
$green-light-1: #e9f4f5;
$green-light-2: #a3c4c5;
$green-dark-1: #4ca7a8;
$blue-700: #175cd3;
$indigo-700: #3538cd;
