@use "src/bundles/UIAppBundle/styles/colors.scss" as c;
@use "src/bundles/UIAppBundle/styles/variables.scss" as v;
@use "src/bundles/UIAppBundle/styles/mixins.scss" as m;

.page-elabel-view {
  @include m.flex(column, flex-start, center);
  margin: 0 auto;
  padding: v.$padding-14 v.$padding-5;
  box-sizing: border-box;

  &-header {
    width: 100%;
    margin-bottom: v.$margin-5;
    @include m.flex(row, space-between, center);

    .logo {
      width: 130px;
    }
  }

  &-company-data {
    font-weight: bold;
    margin: 0;
    font-size: v.$font-size-sm;
    color: c.$black;
  }
  &-company-photo {
    margin-top: v.$margin-4;
    margin-bottom: v.$margin-2;
  }

  &-wine-name {
    margin-top: v.$margin-8;
    font-weight: bold;
    font-size: v.$font-size-lg;
  }

  &-screen-container {
    width: 100vw;
    height: 100vh;
    @include m.flex(row, center, center);
  }

  &-content {
    span {
      font-weight: bold;
    }

    &-block {
      width: 500px;
      max-width: 80%;
      border-top: 1px solid c.$black;
      padding: v.$padding-6 0;
      margin-top: v.$margin-5;
      @include m.flex(column, center, center);
      gap: v.$margin-3;
      text-align: center;

      span {
        color: c.$success-dark;
        font-size: v.$font-size-md;
        font-weight: bold;
      }
    }

    &-caption {
      width: 300px;
      text-align: center;
      background-color: c.$success-dark;
      border-radius: v.$border-radius-6;
      color: c.$white;
      font-weight: bold;
      font-size: v.$font-size-md;
      padding: v.$padding-1 v.$padding-4;
      margin-top: v.$margin-10;
    }

    &-consumption-image {
      object-fit: contain;
    }

    &-flex {
      flex: 1;
    }

    &-table {
      &-description {
        text-align: center;
      }
    }
  }

  &-general {
    width: 100%;
    margin: v.$margin-5 0;
  }

  &-nutrition {
    width: 100%;
    margin: v.$margin-5 0;
  }

  &-ingredients {
    width: 100%;
    margin: v.$margin-5 0;
  }
}

@include m.from-laptop {
  .page-elabel-view {
    width: v.$max-breakpoint-lg;
  }
  .page-elabel-view-content {
    @include m.flex(row, center, center, wrap);
    margin: v.$margin-5 0;
    gap: v.$margin-1;
  }
  .page-elabel-view-content-details-left {
    min-width: 100px;
    width: 300px;
  }
  .page-elabel-view-content-details-right {
    min-width: 100px;
    width: 300px;
  }
  .page-elabel-view-content-details-text-left {
    position: relative;
    margin: v.$margin-10 0;
    text-align: right;
  }
  .page-elabel-view-content-details-text-right {
    position: relative;
    margin: v.$margin-10 0;
    text-align: left;
  }
  .page-elabel-view-content-details-text-left-border {
    border-top: 1px dashed green;
    width: 80px;
    height: 2px;
    position: absolute;
    right: -100px;
    top: 10px;
    z-index: -1;
  }
  .page-elabel-view-content-details-text-right-border {
    border-top: 1px dashed green;
    width: 80px;
    height: 2px;
    position: absolute;
    left: -100px;
    top: 10px;
    z-index: -1;
  }
  .page-elabel-view-content-table {
    width: 666px;
  }
  .svg1 {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
  }
  .svg2 {
    position: absolute;
    left: 0px;
    top: 700px;
    z-index: -1;
  }
  .svg3 {
    position: absolute;
    left: 0px;
    top: 1400px;
    z-index: -1;
  }
  .svg4 {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: -1;
  }
  .svg5 {
    position: absolute;
    right: 0px;
    top: 600px;
    z-index: -1;
  }
  .svg6 {
    position: absolute;
    right: 0px;
    top: 1100px;
    z-index: -1;
  }
}
@include m.until-laptop {
  .page-elabel-view {
    width: 100vw;
    position: relative;
    overflow-x: hidden;
  }
  .page-elabel-view-content {
    @include m.flex(row, center, center, wrap);
    width: 100%;
    margin: v.$margin-5 0;
    gap: v.$margin-1;
    position: relative;
  }
  .page-elabel-view-content-details-left {
    width: 100px;
    position: absolute;
    left: 0;
  }
  .page-elabel-view-content-details-right {
    width: 100px;
    position: absolute;
    right: 0;
  }
  .page-elabel-view-content-details-text-left {
    margin: v.$margin-10 0;
    text-align: left;
    @include m.flex(column);
  }
  .page-elabel-view-content-details-text-right {
    margin: v.$margin-10 0;
    text-align: right;
    @include m.flex(column);
  }
  .page-elabel-view-content-table {
    width: 100%;
  }
  .svg1 {
    position: absolute;
    left: -100px;
    top: 0px;
    z-index: -1;
  }
  .svg2 {
    position: absolute;
    left: -50px;
    top: 700px;
    z-index: -1;
  }
  .svg3 {
    position: absolute;
    left: -50px;
    top: 1400px;
    z-index: -1;
  }
  .svg4 {
    position: absolute;
    right: -50px;
    top: 0px;
    z-index: -1;
  }
  .svg5 {
    position: absolute;
    right: -100px;
    top: 600px;
    z-index: -1;
  }
  .svg6 {
    position: absolute;
    right: -100px;
    top: 1100px;
    z-index: -1;
  }
}

.ant-descriptions-item-content {
  padding: 16px 16px !important;
}
